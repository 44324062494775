<template>
  <div class="bg-grey">
    <!-- Navbar :: start -->
    <b-row v-if="!this.$cookies.get('company_id')">
      <b-col col md="12">
        <NavbarCompNew />
      </b-col>
    </b-row>
    <MainHeader v-if="this.$cookies.get('company_id')" />

    <!-- Navbar :: end -->
    <div class="container-fluid job-listings job-details pb-2">
      <div class="row">
        <div class="col-md-12 px-0 py-3 m-0 position-absolute">
          <img src="@/assets/user-header.jpg" class="img-fluid" />
        </div>
      </div>
      <div class=" my-pt-3 row mx-xl-5 mx-md-1 mt-6 job-details-card">
        <div class="col-md-12 job-post-card mb-0">
          <div class="job-post-header">
            <div class="left">
              <div v-if="job.company_logo_path === null">
                <img width="100" height="50"
                  src="https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg" alt="default" />
              </div>
              <div v-else>
                <img :src="`${image_url}${job.company_logo_path}`" class="img-logo" />
                <!-- {{job}} -->
              </div>
              <div>
                <span>{{ job.company_long_name }}</span>
              </div>
              <div class="d-flex align-items-center">
                <img src="@/assets/app-icons/clock.svg" width="16px" />
                <span class="pl-1"> {{ days }} {{ $gettext("day_ago") }} </span>
                <!-- <span class="ml-5">Deadline {{ job.deadline }}</span> -->
              </div>
            </div>
          </div>
          <div>
            <h5 style=" text-transform: capitalize ;font-family: pn-bold;font-weight: bolder;" class="job-title mb-0">
              {{ job.job_title }}
            </h5>
          </div>
          <div class="job-post-details">
            <div class="my-border-bottom">
              <h6 class="heading">{{ $gettext("Salary_Monthly") }}</h6>
              <div class="d-flex my-typography">
                <div class="mr-2">{{ job.currency_code }}</div>
                <div class="pr-2">
                  {{ job.currency_code && numberWithCommas(job.job_salary_range_from) }}
                </div>

                <div class="pr-2">-</div>
                <div class="mr-2">{{ job.currency_code }}</div>
                <div>{{ job.currency_code && numberWithCommas(job.job_salary_range_to) }}</div>
              </div>
            </div>
            <div class="my-border-bottom">
              <h6 class="heading">{{ $gettext("Job_Type") }}</h6>
              <p class="my-typography">{{ job.job_type }}</p>
            </div>
            <div class="my-border-bottom">
              <h6 class="heading">{{ $gettext("Gender") }}</h6>
              <span class="my-typography">{{ job.gender }}</span>
            </div>
            <div class="my-border-bottom">
              <h6 class="heading">{{ $gettext("Work_from_home") }}</h6>
              <span class="my-typography">
                {{ job.wfh_is_active == "Y" ? $gettext("Available") : $gettext("Not_available") }}
              </span>
            </div>
            <div>
              <h6 class="heading">{{ $gettext("Location") }}</h6>
              <div class="d-flex job-location my-typography">
                <p v-for="c in job.city" :key="c.city_id">
                  {{ c.city_name }}
                </p>
              </div>
            </div>
            <div v-if="$cookies.get('role_id') == 3">
              <button class="btn btn-match" :disabled="true"
                :style="100 <= job.match_percentage && job.match_percentage <= 75 ? 'background-color:#5ACEB1 !important' : 50 <= job.match_percentage && job.match_percentage <= 74.99 ? 'background-color:#5A5AFF !important' : 25 <= job.match_percentage && job.match_percentage <= 49.99 ? 'background-color:#FF8C00 !important' : (24.99 >= job.match_percentage && job.match_percentage >= 0) || !job.match_percentage ? 'background-color: #FF5A5A !important' : ''"
                v-b-tooltip.hover :title="`basic: ${JSON.stringify(job.basic_points_constitute)}`">
                {{ job.match_percentage ? job.match_percentage.toFixed(2) : 0 }}
                % Match &nbsp;<img v-b-tooltip.hover
                  title="The match rated is calculated based on your profile and the job details, if you want to increase your match rate, make sure to answer more questions which are suggested to you in your profile page"
                  src="@/assets/app-icons/question-mark.svg" />
              </button>
            </div>
          </div>
          <div class="job-post-content">
            <div class="grid-half">
              <div class="my-border-bottom">
                <h5 class="title">{{ $gettext("Skills") }}</h5>
                <label style="color: black;" class="label-grey ml-1" v-for="(skill, index) in job.skills" :key="index">{{
                  skill }}</label>
              </div>
              <div>
                <h5 class="title">{{ $gettext("Experience_Required") }}</h5>
                <p class="mb-0">{{ job.job_experiance }} {{ $gettext("Years") }}</p>
              </div>
            </div>
            <div>
              <h5 class="title">{{ $gettext("Description") }}</h5>
              <p class="content mb-0" style="white-space: pre-line">
                {{ job.job_description }}
              </p>
            </div>
            <div>
              <h5 class="title">{{ $gettext("Responsibilities") }}</h5>
              <ul class="px-3 mb-0" v-for="(res, i) in job.resp" :key="i">
                <li>
                  {{ res.job_responsibility }}
                </li>
              </ul>
            </div>
            <div>
              <h5 class="title">{{ $gettext("Education") }}</h5>
              <!-- <p><b>Experience Required: </b>{{ job.job_experiance }} Years</p> -->
              <ul class="px-3 mb-0" v-for="(e, index) in job.edu" :key="index">
                <li>
                  {{ e.job_education }}
                </li>
              </ul>
            </div>
          </div>
          <div class="job-post-footer align-items-center">
            <div class="left">
              <div class="share-text mr-4" @click="share(job)" style="cursor: pointer">
                <span> {{ $gettext("Share") }} </span>
                <img src="@/assets/app-icons/share.svg" width="16px" class="ml-2 mb-0" />
              </div>
              <hr class="vertical-separator" v-if="job.bookmark" />
              <div class="share-text ml-4" @click="AddBookmark(job)" v-if="$cookies.get('role_id') == 3"
                style="cursor: pointer">
                <span> {{ $gettext("Shortlist") }} </span>
                <img v-if="!job.bookmark" src="@/assets/app-icons/bookmark.svg" width="15px" class="ml-2" />
                <img v-if="job.bookmark" src="@/assets/app-icons/yellow-bookmark.svg" width="15px" class="ml-2" />
              </div>
            </div>
            <div class="right">
              <button v-if="$cookies.get('role_id') != 2 && job.applied" class="btn btn-apply" @click="applyJobDetails">{{
                $gettext("Apply") }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-xl-5 mx-md-1 job-details-card" v-show="companyMedia.length > 0">
        <div class="col-md-12 px-0 pb-3">
          <div class="border-bottom flex-between pt-3 ml-4 align-items-center">
            <h4 class="fmp-b font-weight-bold">{{ $gettext("COMPANY_GALLERY") }}</h4>
            <ul class="img-slides slides-arrow d-flex">
              <li class="prev"><i class="fa fa-angle-left"></i></li>
              <li class="next"><i class="fa fa-angle-right"></i></li>
            </ul>
          </div>
          <slick ref="slick" :options="textOptions" v-if="companyMedia.length > 0">
            <div v-for="(data, index) in companyMedia" :key="index" class="pr-4 pt-3 gallery-slider">
              <div class="pt-2 px-2">
                <div v-if="data.company_image_path" v-b-modal.myModal @click="openModal(data)">
                  <img v-if="data.company_image_path" :src="`${image_url}${data.company_image_path}`" class="img-fluid" />
                </div>
                <!-- <CloudFlareVideoPlayer
                v-if="data.user_job_video_path"
                :video-id="data.user_job_video_path"
                :autoplay="false"
              /> -->
                <div v-if="data.user_job_video_path" v-b-modal.myModal @click="openModal(data)" class="position-relative">
                  <img v-if="data.user_job_video_path"
                    :src="`https://videodelivery.net/${data.user_job_video_path}/thumbnails/thumbnail.jpg?time=1s&height=300`"
                    style="border: none" height="300" width="500" />
                  <img src="@/assets/app-icons/orange-video.svg" class="img-center video-play-img" />
                </div>
              </div>
            </div>
          </slick>
        </div>
      </div>
      <div class="row mx-xl-5 mx-md-1" v-show="jobs.length">
        <div class="col-md-12 px-0" v-if="$cookies.get('role_id') == 3">
          <div class="border-bottom flex-between pt-2 align-items-center">
            <h4 class="fmp-b font-weight-bold">{{ $gettext("Other_jobs_with_high_match_rate") }}</h4>
            <ul class="text-slides slides-arrow d-flex">
              <li class="prev"><i class="fa fa-angle-left"></i></li>
              <li class="next"><i class="fa fa-angle-right"></i></li>
            </ul>
          </div>
          <slick ref="slick" :options="jobOptions" v-if="jobs && jobs.length > 0">
            <div v-for="(data, index) in jobs" :key="index" class="job-post-card mb-0">
              <div class="job-post-header">
                <div class="left">
                  <div>
                    <img :src="data.company_logo_path" class="img-logo" />
                  </div>
                  <div>
                    <span>{{ data.company_long_name }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <img src="@/assets/app-icons/clock.svg" width="16px" />
                    <span class="pl-1"> {{ jobsDays[index] }} {{ $gettext("day_ago") }} </span>
                    <!-- <span class="ml-5">Deadline {{ job.deadline }}</span> -->
                  </div>
                </div>
              </div>
              <div class="job-post-details">
                <h5 class="job-title mb-0" style="text-transform: capitalize">
                  {{ data.job_title }}
                </h5>
                <div>
                  <h6 class="heading">{{ $gettext("Salary_Annually") }}</h6>
                  <div class="d-flex">
                    <div class="mr-2">{{ job.currency_code }}</div>
                    <div class="pr-2">{{ formattedNumber(job.job_salary_range_from) }}</div>

                    <div class="pr-2">-</div>
                    <div class="mr-2">{{ job.currency_code }}</div>
                    <div>{{ formattedNumber(job.job_salary_range_to) }}</div>
                  </div>
                </div>
                <div>
                  <h6 class="heading">{{ $gettext("Job_Type") }}</h6>
                  <p>{{ data.job_type }}</p>
                </div>

                <div>
                  <h6 class="heading">Location</h6>
                  <div class="d-flex job-location">
                    <p v-for="c in job.city" :key="c.city_id">
                      {{ c.city_name }}
                    </p>
                  </div>
                </div>
                <div v-if="$cookies.get('role_id') == 3">
                  <button class="btn btn-match" :disabled="true"
                    :style="100 <= data.match_percentage && data.match_percentage <= 75 ? 'background-color:#218838 !important' : 50 <= data.match_percentage && data.match_percentage <= 74.99 ? 'background-color:blue !important' : 25 <= data.match_percentage && data.match_percentage <= 49.99 ? 'background-color:darkorange !important' : (24.99 >= data.match_percentage && data.match_percentage >= 0) || !data.match_percentage ? 'background-color:red !important' : ''">
                    {{ data.match_percentage ? data.match_percentage.toFixed(2) : 0 }}
                    % Match &nbsp;<img v-b-tooltip.hover
                      title="The match rated is calculated based on your profile and the job details, if you want to increase your match rate, make sure to answer more questions which are suggested to you in your profile page"
                      src="@/assets/app-icons/question-mark.svg" />
                  </button>
                </div>
              </div>
              <div class="job-post-content">
                <div>
                  <h5 class="title">{{ $gettext("Description") }}</h5>
                  <p class="content mb-0">
                    {{ data.job_description }}
                  </p>
                </div>
              </div>
              <div class="job-post-footer align-items-center">
                <div class="left">
                  <div class="share-text mr-4" @click="share(data)" style="cursor: pointer">
                    <span> {{ $gettext("Share") }} </span>
                    <img src="@/assets/app-icons/share.svg" width="16px" class="ml-2 mb-0" />
                  </div>
                  <hr class="vertical-separator" />
                  <div class="share-text ml-4" @click="AddBookmark(data, index)" v-if="$cookies.get('role_id') == 3"
                    style="cursor: pointer">
                    <span> {{ $gettext("Shortlist") }} </span>
                    <img v-if="!data.bookmark" src="@/assets/app-icons/bookmark.svg" width="15px" class="ml-2" />
                    <img v-if="data.bookmark" src="@/assets/app-icons/yellow-bookmark.svg" width="15px" class="ml-2" />
                  </div>
                </div>
                <div class="right">
                  <button v-if="$cookies.get('role_id') != 2" class="btn btn-apply" @click="applyJobs(data)">{{
                    $gettext("Apply") }}</button>
                </div>
              </div>
            </div>
          </slick>
        </div>
      </div>
      <b-modal id="myModal" size="lg" ok-only hide-header>
        <img v-if="selectedImg" :src="`${image_url}${selectedImg.image_path}`" class="modal-img" />
        <iframe v-if="selectedVideo"
          :src="`https://iframe.videodelivery.net/${selectedVideo.video_path}/thumbnails/thumbnail.jpg?time=1s&height=500`"
          style="border: none" height="500" width="750"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"></iframe>
      </b-modal>
    </div>
    <b-modal id="share-modal" ref="modal" @hidden="resetModal" @ok="handleOk" hide-footer>
      <div>
        <h4>{{ $gettext("Share_this_job_on_social_network") }}</h4>
        <div class="social-icons-grid">
          <ShareNetwork network="Whatsapp" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-whatsapp"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Facebook" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-facebook"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Twitter" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-twitter"></i>
            </div>
          </ShareNetwork>

          <!-- <ShareNetwork network="fakeblock" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </div>
          </ShareNetwork> -->
          <!-- 
          <a :href="`https://www.snapchat.com/scan?attachmentUrl=${SERVERURL + 'job-details/' + selectedJob.job_id}`"
            target="_blank">
            <i class="fa fa-snapchat-square" aria-hidden="true"></i>
          </a> -->


          <!-- <a href="https://www.tiktok.com/en/" target="_blank">
            <div style="width:17.15px; margin-top: -2px;">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333335 333336" shape-rendering="geometricPrecision"
                text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd"
                clip-rule="evenodd">
                <path
                  d="M72464 0h188407c39856 0 72464 32609 72464 72465v188407c0 39855-32608 72464-72464 72464H72464C32608 333336 0 300727 0 260872V72465C0 32609 32608 0 72464 0zm127664 70642c337 2877 825 5661 1461 8341l6304 2c1170 9991 4006 19119 8465 26697 7282 6745 16797 10904 28280 11641v9208c2131 444 4350 746 6659 894v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-20546 35222-58194 36677-82176 18323-12269-4256-23069-12466-29881-23612-19875-32516-1959-85512 55687-90966l-94 7835v1970c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-7424 14197-4049 26526 3716 34309 16276 2796 34401-8481 31673-43351V70644h33628z"
                  fill="#1a121f" />
                <path
                  d="M200128 70642c3093 26406 18915 45038 44510 46681v25046l-165 15v-21275c-25595-1642-40311-17390-43404-43796l-27114-1v111095c3912 50005-35050 51490-49955 32531 17482 10934 45867 3826 42501-39202V70641h33628zm-72854 184165c-15319-3153-29249-12306-37430-25689-19875-32516-1959-85512 55687-90966l-94 7835c-53444 8512-58809 65920-44009 89802 5707 9209 15076 15686 25846 19019z"
                  fill="#26f4ee" />
                <path
                  d="M207892 78985c1761 15036 7293 28119 16454 36903-12866-6655-20630-19315-23062-36905l6609 2zm36580 47511c2181 463 4456 777 6824 929v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-21443 36760-61517 36743-85239 15810 30930 17765 84928 3857 84829-56453v-55496c15141 9105 28134 13993 42981 12530v-24302zm-99036 21460c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-10441 19964 474 36238 14923 41365-18075-649-36010-19214-23555-43031 3707-7089 10474-10923 22958-12980v-28177z"
                  fill="#fb2c53" />
                <path
                  d="M201068 77313c3093 26406 17809 42154 43404 43796v29689c-14847 1462-27841-3425-42981-12530v55496c119 72433-77802 77945-100063 42025-14800-23881-9435-81290 44009-89802v30147c-12483 2057-19250 5891-22958 12980-22909 43808 56997 69872 51475-706V77313l27114 1z"
                  fill="#fefefe" />
              </svg>
            </div>
          </a> -->
          <ShareNetwork network="Linkedin" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-linkedin"></i>
            </div>
          </ShareNetwork>
        </div>
      </div>
    </b-modal>
    <!-- ----------------------------- -->
    <b-modal :ref="'modal'" size="lg" modal-class="job-modal">
      <div slot="modal-header">
        <div class="d-flex align-items-center border-right pr-2">
          <button class="btn btn-danger fs-12"
            :style="100 <= sJob.match_percentage && job.match_percentage <= 75 ? 'background-color:#218838 !important' : 50 < sJob.match_percentage && sJob.match_percentage <= 74.99 ? 'background-color:blue !important' : 25 < sJob.match_percentage && sJob.match_percentage <= 49.99 ? 'background-color:darkorange !important' : (24.99 > sJob.match_percentage && sJob.match_percentage >= 0) || !sJob.match_percentage ? 'background-color:red !important' : ''"
            :disabled="true">
            {{ sJob.match_percentage }}% Match &nbsp;<img src="@/assets/app-icons/question-mark.svg" width="10px" />
          </button>
        </div>
        <div class="border-right pr-2">
          <img :src="sJob.company_logo_path" width="50" height="50" />
          <p class="fs-12 f-light mb-0">{{ sJob.company_long_name }}</p>
        </div>
        <div class="border-right pr-2">
          <h6 class="fmp-b">{{ sJob.job_title }}</h6>
          <p class="fs-12 f-light mb-0"><i class="fa fa-clock-o mr-1"></i>15d ago</p>
        </div>
        <div class="border-right pr-2">
          <h6 class="f-light fs-13">{{ $gettext("Salary_Annually") }}</h6>
          <h6 class="mb-0">{{ sJob.currency_code }}{{ " " }}{{ formattedNumber(sJob.job_salary_range_to) }}</h6>
        </div>
        <div>
          <h6 class="f-light fs-13">{{ $gettext("Location") }}</h6>
          <h6 class="mb-0">{{ sJob.city_name }}</h6>
        </div>
      </div>
      <div>
        <p class="fs-15 text-center">
          {{ $gettext("Your_match_rate_of") }}{{ sJob.match_percentage }}% {{ $gettext("is_lower_than") }}
          <br />
          {{ $gettext("other_applications_recieved") }}
        </p>
        <p class="fs-15 text-center">
          {{ $gettext("You_can_however_still_make_a_difference_by_adding_a") }}<br />
          {{ $gettext("video_profile_with_a_relevant_and_impactful_message_to") }}<br />
          {{ $gettext("the_employer") }}
        </p>
      </div>

      <div slot="modal-footer">
        <p class="f-coral mb-0 fpn-b pr-3 text-center" style="cursor: pointer" @click="
          $router.push({
            name: 'Job-Application',
            params: { job_id: sJob.job_id },
          })
          ">
          {{ $gettext("I_understand") }}<br />
          {{ $gettext("continue_to_apply_now") }}
        </p>
        <button class="btn btn-success btn-custom" @click="() => {
          if ($cookies.get('role_id') == 3 && selectedSub && selectedSub.subs_pkg_no_of_videos > 0) {
            $router.push({ name: 'Applicant-Onboarding-4' });
          } else {
            this.$toasted.show('You dont have subscriptions to add video', {
              duration: 6000,
            });
            $router.push({ name: 'Applicant-Settings' });
          }
        }
          ">
          {{ $gettext("ADD_VIDEO_PROFILE") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApplicantJobService from "@/apiServices/ApplicantJobServices";
import ApplyJobService from "@/apiServices/ApplyJobServices";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import CompanyServices from "@/apiServices/CompanyServices";
import CloudFlareVideoPlayer from "vue-cloudflare-video-player";
import moment from "moment";
import { SERVERURL } from "@/config";
import { numberWithCommas } from "@/helpers/HelperFunctions.js";
import { IMAGEURL } from "@/config";
import numeral from 'numeral'
import NavbarCompNew from "../../components/shared/NavbarCompNew.vue";
import MainHeader from "@/views/partials/MainHeader.vue";

export default {
  components: { CloudFlareVideoPlayer, NavbarCompNew, MainHeader },
  data() {
    return {
      image_url: IMAGEURL,
      job: { applied: false },
      jobs: [],
      loader: null,
      loader2: null,
      SERVERURL: SERVERURL,
      selectedSub: null,
      selectedJob: {},
      days: null,
      sJob: {},
      jobsDays: [],
      role_id: this.$cookies.get("role_id"),
      appliedJobs: [],
      appDetails: null,
      companyMedia: [],
      selectedImg: null,
      selectedVideo: null,
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
        role_id: this.$cookies.get("role_id"),
        user_id: this.$cookies.get("user_id"),
      },
      textOptions: {
        arrows: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 2,
        prevArrow: ".img-slides .prev",
        nextArrow: ".img-slides .next",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
      jobOptions: {
        arrows: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        prevArrow: ".text-slides .prev",
        nextArrow: ".text-slides .next",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
    };
  },
  beforeMount() {
    this.getApplicantDetails();
    if (this.$cookies.get("role_id") == 3) {
      this.getSubs();
    }
    // console.log(this.jobs);
  },
  methods: {
    formattedNumber(sal) {
      //console.log(sal);

      return numeral(sal).format('0,0');
    },
    numberWithCommas: numberWithCommas,
    resetModal() {
      this.selectedJob = {};
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    getSubs() {
      const user_id = this.$cookies.get("user_id");
      ApplicantProfileService.getUserSubscription(user_id).then((res) => {
        this.selectedSub = res.data.data.length ? res.data.data[0] : null;
      });
    },
    handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },
    share(job) {
      console.log(`job=====`, job);
      this.selectedJob = job;
      this.$bvModal.show("share-modal");
    },
    async getJobDetails() {
      const job_id = this.$route.params.id;
      const user_id = this.$cookies.get("user_id");
      const role_id = this.$cookies.get("role_id");

      console.log("job_id :>> ", job_id);
      try {
        const res = await ApplicantJobService.GetSingleJob({
          job_id,
          user_id,
          role_id,
        });
        this.job = res.data.data[0];

        this.job.applied = true;

        if (this.$cookies.get("role_id") == 3) {
          // this.job.match_percentage = this.job.match_percentage;
        }

        this.loader.hide();
        this.daysAgo();
        this.getSingleCompany(this.job.company_id);
      } catch (error) {
        this.loader.hide();
      }
    },
    handleSuccess(e) {
      // console.log("success", e);
      // this.copiedJobLink = `${SERVERURL}job-details/${e}`;
      // console.log("this.copiedJobLink :>> ", this.copiedJobLink);
      this.$toasted.show("Copied to clipboard", {
        duration: 6000,
        icon: "check",
      });
    },
    handleError(e) {
      console.log("failed", e);
    },

    getJobBookmark() {
      if (this.$cookies.get("AIAPADHASHID") && this.$cookies.get("role_id") == 3) {
        const user_id = this.$cookies.get("user_id");
        const job_id = this.$route.params.id;

        ApplicantJobService.GetSingleBookmark({ user_id, job_id })
          .then((res) => {
            if (res.data.data && res.data.data.bookmark_active == "Y") {
              this.job.bookmark = true;
            } else {
              this.job.bookmark = false;
            }

            this.loader.hide();
            this.$forceUpdate();
          })
          .catch((err) => {
            console.log("err :::::>> ", err);
            this.loader.hide();
          });
      }
    },
    // getAllBookmark() {
    //   const user_id = this.$cookies.get("user_id");
    //   ApplicantJobService.GetBookmark({ user_id }).then((res) => {
    //     const data = res.data.data.forEach((job, index) => {
    //       if (job.job_active === "Y") {
    //         job.bookmark = true;
    //         this.jobs.push(job);
    //       }
    //     });
    //     this.jobs = this.jobs.map((m) => {
    //       return {
    //         ...m,
    //         deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
    //       };
    //     });
    //   });
    // },
    AddBookmark(job, index) {
      console.log("job :>> ", job);
      const user_id = this.$cookies.get("user_id");
      const job_id = job.job_id;
      ApplicantJobService.AddBookmark({ user_id, job_id })
        .then((res) => {
          if (res.data.data.bookmark_active == "Y") {
            if (index != undefined) {
              this.jobs[index].bookmark = true;
              this.$forceUpdate();
            } else {
              this.job.bookmark = true;
              this.$forceUpdate();
            }
          } else {
            if (index != undefined) {
              this.jobs[index].bookmark = false;
              this.$forceUpdate();
            } else {
              this.job.bookmark = false;
              this.$forceUpdate();
            }
            // this.getJobsBookMarkv2();
          }
        })
        .catch((err) => {
          console.log("job :>> ", job.bookmark_id);
          // this.getSingleBookmark(job.bookmark_id);
          //  this.jobs[index].bookmark = false;
        });
    },

    getApplicantDetails() {
      const applicant_id = this.$cookies.get("user_id");
      this.loader = this.$loading.show();
      if (this.role_id == 3) {
        ApplicantProfileService.getApplicantProfile({ applicant_id })
          .then((res) => {
            this.appDetails = res.data.data.user_data[0];
            this.appDetails.skills = this.appDetails.skills.map(({ skill_name }) => {
              return skill_name;
            });

            this.getJobDetails();

            this.getAllJobs(this.pagination);
          })
          .catch((err) => {
            this.loader.hide();
          });
      } else {
        this.getJobDetails();
        this.getAllJobs(this.pagination);
      }
    },
    daysAgo() {
      let now = moment();

      let jobCreated = moment(this.job.job_start_date);
      console.log("jobCreated :>> ", jobCreated);
      let diff = now.diff(jobCreated, "day");

      this.days = diff;
    },
    openModal(data) {
      if (data.company_image_path) {
        this.selectedVideo = null;
        this.selectedImg = {
          image_path: data.company_image_path,
          image_id: data.company_image_id,
        };
      }
      if (data.user_job_video_path) {
        this.selectedImg = null;
        this.selectedVideo = {
          video_path: data.user_job_video_path,
          video_id: data.user_job_video_id,
        };
      }
      this.$bvModal.show("myModal");
    },
    getAppliedJobs() {
      if (this.$cookies.get("AIAPADHASHID")) {
        this.pagination.user_id = this.$cookies.get("user_id");

        ApplyJobService.GetAllAppliedJobs(this.pagination)
          .then((res) => {
            this.appliedJobs = res.data.data.data;
            console.log("this.jobs.length :>> ", this.jobs.length);
            this.appliedJobs.forEach((aJob, index) => {
              if (this.job.job_id == aJob.job_id) {
                this.job.applied = false;
              }
              // this.jobs.forEach((j, i) => {
              //   if (j.job_id == aJob.job_id) {
              //     this.jobs[i].applied = false;
              //   }
              // });
              this.jobs = this.jobs.filter((f) => {
                if (f.job_id == aJob.job_id) {
                  return false;
                } else {
                  return true;
                }
              });
            });
            console.log("this.jobs.length ===:>> ", this.jobs.length);
            this.$forceUpdate();

            this.getAllBookmark();
          })
          .catch((e) => {
            console.log("e :>> ", e);

            this.loader2.hide();
          });
      } else {
        this.loader2.hide();
      }
    },

    // shortlist() {
    //   let payload = {
    //     user_id: this.$cookies.get("user_id"),
    //     company_id: this.$cookies.get("company_id"),
    //     job_id: this.$route.params.id,
    //   };
    //
    //   RecruiterJobService.ShortlistJob(payload)
    //     .then((s) => {
    //       console.log("s :>> ", s);
    //       this.$toasted.show("Job shortlisted successfully!", {
    //         duration: 6000,
    //         icon: "check",
    //       });
    //     })
    //     .catch((err) => {
    //
    //       this.$toasted.show("This Job already shortlisted!", {
    //         duration: 6000,
    //         icon: "clear",
    //       });
    //     });
    // },
    getSingleCompany(company_id) {
      CompanyServices.GetSingleCompany({ company_id })
        .then((res) => {
          this.companyMedia = res.data.data[0].images;
          res.data.data[0].videos.forEach((v) => {
            this.companyMedia.push(v);
          });
          this.getJobBookmark();
        })
        .catch((err) => {
          if (this.loader) {
            this.loader.hide();
          }
        });
    },
    applyJobDetails() {
      const user_id = this.$cookies.get("user_id");
      const token = this.$cookies.get("AIAPADHASHID");
      const role_id = this.$cookies.get("role_id");
      const job_id = this.$route.params.id;
      if (this.job.match_percentage < 49) {
        this.sJob = this.job;
        this.$refs["modal"].show();
      } else {
        const payload = { user_id: user_id, job_id: job_id };
        if (token && role_id == 3) {
          this.$router.push({
            name: "Job-Application",
            params: { job_id: job_id },
          });
        } else {
          // this.$router.push("/register");
          this.$router.push("/download-zaajira-app");
        }
      }
    },
    applyJobs(job) {
      const token = this.$cookies.get("AIAPADHASHID");
      const role_id = this.$cookies.get("role_id");
      if (job.match_percentage < 49) {
        this.sJob = job;
        this.$refs["modal"].show();
      } else {
        if (token && role_id == 3) {
          this.$router.push({
            name: "Job-Application",
            params: { job_id: job.job_id },
          });
        } else {
          // this.$router.push({ name: "Register" });
          this.$router.push("/download-zaajira-app");
        }
      }
    },

    getAllJobs(payload) {
      this.loader2 = this.$loading.show();
      ApplicantJobService.GetAllJobs(payload)
        .then((res) => {
          this.jobs = res.data.data.data;
          console.log("jobs :::::::::::::::::::::::::>> ", this.jobs);
          console.log("this.job :>> ", this.job);
          this.jobs = this.jobs.filter((f) => {
            if (this.job.job_id == f.job_id) {
              return false;
            } else {
              if (this.job.match_percentage < f.match_percentage) {
                return true;
              }
            }
          });

          this.getAppliedJobs();

          this.jobs.forEach((job, index) => {
            let now = moment().format("YYYY-MM-DD");
            let jobCreated = moment(job.job_start_date);
            let diff = jobCreated.diff(now, "day") * -1;

            this.jobsDays.push(diff);
          });
          this.loader2.hide();
          // this.getAllBookmark();
        })
        .catch((err) => {
          if (this.loader2) {
            this.loader2.hide();
          }
        });
    },
    getAllBookmark() {
      const user_id = this.$cookies.get("user_id");

      ApplicantJobService.GetBookmark({ user_id })
        .then((res) => {
          const bm = res.data.data.map((m) => {
            return m.job_id;
          });
          console.log("res.data.data ::::::>> ", res.data.data);
          let bmJobs = this.jobs.filter((f) => {
            if (bm.includes(f.job_id)) {
              return true;
            }
          });
          bmJobs = bmJobs.map((m) => {
            return {
              ...m,
              bookmark: true,
            };
          });
          console.log("bmJobs :>> ", bmJobs);
          this.jobs = this.jobs.map((j) => {
            return bmJobs.find((o) => o.job_id === j.job_id) || j;
          });

          this.loader2.hide();

          // this.jobs.forEach((job, index) => {
          //   bm.forEach((b) => {
          //     if (job.job_id == b.job_id) {
          //       this.jobs[index].bookmark_id = b.bookmark_id;
          //       this.jobs[index].bookmark = false;
          //     } else {
          //       //console.log("hitting :>> ", job.job_id == b.job_id);
          //       //this.jobs[index].bookmark = false;
          //     }
          //   });
          // });
        })
        .catch((e) => {
          console.log("e :>> ", e);
          if (this.loader2) {
            this.loader2.hide();
          }
        });
    },
  },
};
</script>

<style>
.label-grey,
.label-grey-light {
  color: #000;
  letter-spacing: 1.5px;
  font-size: 13px;
  padding: 5px 10px;
}

.slides-arrow li {
  list-style-type: none;
}

.slides-arrow li .fa {
  font-size: 30px;
  color: black;
  cursor: pointer;
  padding-right: 30px;
}

.gallery-slider img {
  height: 300px;
  width: 95%;
  -o-object-fit: cover;
  object-fit: cover;
  /* margin: 0 10%; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.my-typography {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 19.392px;
}

.my-pt-3 {
  padding-top: 3rem !important;
}

@media only screen and (max-width:768px) {

  .my-pt-3 {
    padding-top: 0rem !important;
  }

  .job-post-card .job-post-details {
    display: grid;
    /* grid-template-columns: auto auto auto auto auto; */
    grid-template-columns: 100%;
    grid-gap: 10px;
    border-bottom: 1px solid #dee2e6 !important;
    align-items: center;
    padding: 0.8rem 0 !important;
  }

  .my-border-bottom {
    border-bottom: 2px solid #0000000f;
    padding-bottom: 10px;
  }

  .job-listings .job-post-content .grid-half {

    grid-template-columns: 100%;

  }


}
</style>
